
/* eslint-disable react/no-array-index-key */

import React from 'react';

import { graphql, StaticQuery } from 'gatsby';

import { portfolioCategoriesType, portfolioProjectsType } from '../../types';

class Portfolio extends React.Component {
	constructor(props) {
		super(props);
		this.state = { currentCategory: 0 };
	}

	onClickCategory = (category) => {
		this.setState({ currentCategory: category });
	};

	render() {
		// console.log('Portfolio; props ', this.props);
		const { categories, projects } = this.props;
		return (
			<section id='portfolio'>
				<div className='container-fluid'>

					<div id='portfolio-menu'>
						<ul>
							{categories.map((item) => (
								<li
									key={item.id}
									className={item.id === this.state.currentCategory ? 'filter active' : 'filter'}
									onClick={() => this.onClickCategory(item.id)}
								>
									{item.title}
									<div className='portfolio-icon' />
								</li>
							))}
						</ul>
					</div>

					<div id='portfolio-content'>
						{projects
							.filter(
								(project) => this.state.currentCategory === 0 || this.state.currentCategory === project.category
							)
							.map((project) => (
								<div key={project.id} className='card-container'>
									<div className='card'>
										<div className='face front'>
											<img src={project.img} alt={project.title} />
										</div>
										<div className='face back'>
											<div className='card-text'>
												<a href={project.url} target='_blank' rel='noopener noreferrer'>
													<h2>{project.title}</h2>
													<p>{project.content}</p>
												</a>
											</div>
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</section>
		);
	}
}

Portfolio.propTypes = {
	projects: portfolioProjectsType.isRequired,
	categories: portfolioCategoriesType.isRequired
};

export default () => (
	<StaticQuery
		query={graphql`
			query {
				contentPortfolioJson {
					projects {
						id
						category
						img
						title
						content
						url
					}
					categories {
						id
						title
					}
				}
			}
		`}
		render={({ contentPortfolioJson }) => (
			<Portfolio projects={contentPortfolioJson.projects} categories={contentPortfolioJson.categories} />
		)}
	/>
);
