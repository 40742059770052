
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import MainLayout from '../components/MainLayout';

import SubpageHeader from '../components/SubpageHeader';

import Portfolio from '../components/portfolio/Portfolio';
import Technologies from '../components/Technologies';

import createMetaData from '../utils/createMetaData';

const PagePortfolio = () => {
	const { pagePortfolioJson: json } = useStaticQuery(graphql`
		query {
			pagePortfolioJson {
				meta_description
				meta_keywords
				meta_title
				permalink
				title
			}
		}
	`);
	const { site, page, seo } = createMetaData(json);

	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<Portfolio />
			<Technologies />
		</MainLayout>
	);
};

export default PagePortfolio;
